import Plyr from "plyr";

export default ($) => {
  function init() {
    setTimeout(() => {
      video_widget_implement();
    }, 1000);
  }

  async function video_widget_implement() {
    const settings = window.vdrv_settings.settings;

    if (!settings) {
      return;
    }

    load_styles(settings.css_link)

    settings.cur_url = location.protocol + '//' + location.host + location.pathname

    let widget_html = false;

    try{
      const response = await fetch(settings.rest_api_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(settings)
      });
      widget_html = await response.json();
    } catch {
      console.log("vdrv Some issue happened");
    }

    if(!widget_html.success){
      return;
    }

    document.body.append(stringToHTML(widget_html.data));

    const container = document.querySelector("#vdrv-widget")
    const widget = container.querySelector("#vdrv-widget-video-wrapper")
    const close_collapse_button = document.querySelector(".vdrv-widget-close")
    const cta_button = document.querySelector("#vdrv-widget #vdrv-cta-button")

    if (!widget) {
      return;
    }

    const player = new Plyr("#player", {
      controls: [],
      blankVideo:'https://cdn.plyr.io/static/blank.mp4',
      playsinline: true,
      muted: true,
      clickToPlay: false,
      disableContextMenu: true,
      autoplay: false,
      loop: { active: true },
      keyboard: {
        global: false,
      },
      tooltips: {
        controls: true,
      },
      captions: {
        active: true,
      },
      fullscreen: {
        enabled: false,
        fallback: true,
        iosNative: false,
        container: null,
      },
      vimeo: {
        byline: false,
        portrait: false,
        title: false,
        speed: false,
        transparent: false,
        background: true,
        byline: false,
        controls: false,
      },
      youtube: {
        noCookie: true,
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3,
        modestbranding: 0,
      },
    });

    player.on("ready", (event) => {
      console.log('vdrv player ready');
      player.muted = true;
      player.play();
    });

    player.once("playing", (event) => {
      console.log('vdrv video start playing');
      container.classList.add("show");
    });

    /**
     * Widget on click logic
     */
    widget.onclick = () => {
      /**
       * On opening/opened
       */
      if (
        !widget.classList.contains("closed") &&
        !widget.classList.contains("active")
      ) {
        widget.classList.add("active");
        player.muted = !player.muted;

        if (cta_button) {
          cta_button_logic();
        }

        // start video from beginning if needed
        if (settings.s_video_from_start) {
          player.restart();
          player.play();
        }

        widget.style.borderColor = settings.on_hover_widget_border;
      } else if (widget.classList.contains("active")) {
        /**
         * on collapsing
         */
        if (player.paused == true) {
          // Play the video
          player.play();
        } else {
          // Pause the video
          player.pause();
        }

        widget.style.borderColor = settings.default_widget_border;
      }
    };

    /**
     * On hover
     * @param {*} ev
     */
    widget.onmouseover = (ev) => {
      widget.style.borderColor = settings.on_hover_widget_border;
    };

    /**
     * On mouse out
     * @param {*} ev
     */
    widget.onmouseout = (ev) => {
      /**
       * If widget is not active
       */
      if (!widget.classList.contains("active")) {
        widget.style.borderColor = settings.default_widget_border;
      }
    };

    /**
     * CTA Button logic
     */
    function cta_button_logic() {
      let seconds = settings.cta_time
        ? parseInt(settings.cta_time) * 1000
        : 1000;
      setTimeout(() => {
        cta_button.classList.add("active");
      }, seconds);

      /**
       * If cta button is selected type link
       */
      if (settings.cta_type === "link") {
        cta_button.onclick = () => {
          window.open(
            settings.cta_link.url,
            settings.cta_link.blank ? "_blank" : "_self"
          );
        };
      } else if (settings.cta_type === "action") {
        cta_button.onclick = () => {
          let element = document.querySelector(settings.cta_action.selector);
          if (element) {
            element.click();
          }
        };
      } else if (settings.cta_type === "scroll") {
        cta_button.onclick = () => {
          let element = document.getElementById(settings.cta_scroll.id);
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest",
            });
          }
        };
      }
    }

    /**
     * Convert a template string into HTML DOM nodes
     * @param  {String} str The template string
     * @return {Node}       The template HTML
     */
    function stringToHTML(str) {
      var dom = document.createElement("div");
      dom.innerHTML = str;
      return dom;
    }

    close_collapse_button.onclick = () => {
      if (widget.classList.contains("active")) {
        widget.classList.remove("active");
        widget.classList.add("closed");
        if (cta_button) {
          cta_button.classList.remove("active");
        }

        player.muted = !player.muted;
        player.play();
        setTimeout(() => {
          widget.classList.remove("closed");
        }, 1000);
      } else if (!widget.classList.contains("active")) {
        container.remove();
      }
    };
  }

  function load_styles(link_href){
    // Create new link Element
    let link = document.createElement('link');

    // set the attributes for link element
    link.rel = 'stylesheet';

    link.type = 'text/css';

    link.href = link_href;

    // Get HTML head element to append
    // link element to it
    document.getElementsByTagName('HEAD')[0].appendChild(link);
  }

  /**
   * Load after full page ready + some seconds
   */
  custom_on_load(init);

  /**
   * On load custom function
   * @param {*} callback
   */
  function custom_on_load(callback) {
    if (window.addEventListener)
      window.addEventListener("load", callback, false);
    else if (window.attachEvent) window.attachEvent("onload", callback);
    else window.onload = callback;
  }
};
